import { apiPrefix } from 'App';
import { appDispatch } from 'modules/MRF';
import { clearAuthDataAction } from 'modules/redux/actions/auth';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import urljoin from 'url-join';
import notification, { TYPE } from 'modules/utils/notification';
import { mrfErrorValidationHandler } from 'modules/view/FormCRUD';
import { setFormValueAction } from 'modules/redux/actions/form';
import { logFetch } from 'modules/Dao';

let logoutTimeout = undefined;
let attemptingRenewal = false;

export const loadJwt = jwt => {
    localStorage.token = 'Bearer ' + jwt;
    localStorage.tokenData = atob(localStorage.token.split('.')[1]);

    const tokenJsonData = JSON.parse(localStorage.tokenData);
    localStorage.tokenRenewal = tokenJsonData.tokenRenewalTimestamp;
    localStorage.tokenExp = new Date(tokenJsonData.exp * 1000);

    window.location.reload()
}

export const logout = () => {
    if (localStorage.token) {
        delete localStorage.token;

        if (appDispatch?.value) {
            appDispatch.value(clearAuthDataAction());
        } else {
            window.location.reload()
        }
    }
};

// The return code specifies if the timer has started.
export const startLogoutTimeout = () => {
    if (!localStorage.token) {
        return false;
    }

    const now = new Date();
    const tokenExp = new Date(localStorage.tokenExp);
    const msUntilExpiry = tokenExp - now;

    if (msUntilExpiry < 0) {
        return false;
    }

    clearTimeout(logoutTimeout);
    logoutTimeout = setTimeout(() => {
        if (!startLogoutTimeout()) {
            logout();
        }
    }, msUntilExpiry);
    return true;
}

export const attemptTokenRenewal = async () => {
    const now = new Date()
    const tokenRenewal = new Date(localStorage.tokenRenewal);

    if (now > tokenRenewal && !attemptingRenewal) {
        try {
            attemptingRenewal = true;

            // This comparison is separate for efficiency.
            const tokenExp = new Date(localStorage.tokenExp);
            if (now < tokenExp) {
                const url = apiPrefix ? urljoin(apiPrefix, '/api/Users/RenewalLogin') : '/api/Users/RenewalLogin';

                const response = await logFetch(
                    url,
                    {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            ...localStorage.token ? { Authorization: localStorage.token } : {},
                        }
                    }
                );

                if (response.status === 401){
                    console.error('Expired token, cleaning localStorage');
                    logout();
                    return;
                }

                if (response.status !== 200) {
                    console.error('Unable to renewal token! Error code: ' + response.status);
                    return;
                }

                const ret = await response.json();
                loadJwt(ret);
            }
        } finally {
            attemptingRenewal = false;
        }
    }
}

// This component could be a react hooks `use` function, but that
// would trigger unnecessary re-renders on it's parent component.
export const RenewalWatcher = () => {
    // This is here to trigger a re-render of this component every time
    // - something happens in the redux state
    // - the url changes
    useSelector(state => state);
    useLocation();

    useEffect(() => {
        attemptTokenRenewal();
    });

    return false;
}

export const userErrorHandler = (e, context) => {
    if (!e?.status) {
        return;
    }

    if(e?.status === 302){
        appDispatch.value(setFormValueAction('loginForm', { requiresTwoFactor: true }));
        notification({ type: TYPE.INFO, message: "This account has two factor authentication, please insert your verification code" });
        console.log('Account has two factor authentication enabled');
    } else if (e?.status === 404) {
        notification({ type: TYPE.ERROR, message: 'Invalid credentials. Please check your username or password.' });
    } else if (e?.status === 401) {
        notification({ type: TYPE.ERROR, message: 'Action Unauthorized' });
    } else if (e?.status === 400) {
        mrfErrorValidationHandler(e, context);
    } else if (e?.status === 500) {
        notification({ type: TYPE.ERROR, message: 'Internal Server Error' });
        console.log('Internal Server Error:', e);
    } else if (e?.status === 412) {
        if (e.condition) {
            appDispatch.value(setFormValueAction('loginForm', { licenseRequestCode: e.condition }));
        }
        console.log('Precondition failed:', e);
    } else {
        notification({ type: TYPE.ERROR, message: 'Unknown error...' });
        console.log('Unknown error:', e);
    }
}
