import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { daoPost } from 'modules/Dao';
import moment from 'moment';

let missingKeys = [];
export const globalTranslationTerms = {};
let timeout = undefined;

const addMissingKey = (_, namespace, key) => {
    const url = window.location.href;

    if (globalTranslationTerms[namespace]?.[key] || url.endsWith('/newVersion')) {
        return;
    }

    clearTimeout(timeout);

    missingKeys.push({
        term: key,
        namespace,
        url,
    });

    timeout = setTimeout(() => {
        if(!localStorage.token) return;
        daoPost('/api/TranslationTerms/list', { terms: missingKeys }, 'execute', false);
        missingKeys = [];
    }, 1000);
}

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {},
        fallbackLng: 'en',

        debug: false,
        saveMissing: true,
        missingKeyHandler: addMissingKey,

        nsSeparator: false,
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    });

moment.updateLocale('en', {
    longDateFormat : {
        L : 'MM/DD/YYYY',
    }
});

moment.updateLocale('es', {
    longDateFormat : {
        L : 'DD/MM/YYYY',
    }
});

export default i18n;
