import { addQueryResourceAction, addResourceAction, addResourceByIdAction, addResourceRaw } from './actions/resource';
import { daoGet } from '../Dao';
import { Flatten } from './Mapper';

export async function resourceGetList({ dispatch, resource, endpoint, query, sorter = i => i, descriptor, outerMap = o => o, innerMap = o => o, errorHandler }) {
    try {
        const data = await daoGet(endpoint, query);

        dispatch(addResourceAction(resource, sorter(outerMap(data))?.map?.(o => innerMap(Flatten(dispatch, o, descriptor)))));
    } catch (e) {
        console.error(e)
        if (errorHandler !== undefined) {
            if (errorHandler !== false) {
                errorHandler(e)
            }
        } else {
            throw e;
        }
    }
}

export async function resourceGetQueryList({ dispatch, resource, endpoint, query, sorter = i => i, descriptor, outerMap = o => o, innerMap = o => o, errorHandler }) {
    try {
        const data = await daoGet(endpoint, query);

        dispatch(addQueryResourceAction(resource, sorter(outerMap(data)).map(o => innerMap(Flatten(dispatch, o, descriptor)))));
    } catch (e) {
        console.error(e)
        if (errorHandler !== undefined) {
            if (errorHandler !== false) {
                errorHandler(e)
            }
        } else {
            throw e;
        }
    }
}

export async function resourceGetObject(dispatch, resource, endpoint, query, descriptor, map = o => o, errorHandler) {
    try {
        const data = await daoGet(endpoint, query);

        dispatch(addResourceByIdAction(resource, [ map(Flatten(dispatch, data, descriptor)) ]));
    } catch (e) {
        console.error(e)
        if (errorHandler !== undefined) {
            if (errorHandler !== false) {
                errorHandler(e)
            }
        } else {
            throw e;
        }
    }
}

export async function resourceGetRaw(dispatch, resource, endpoint, query, map = o => o, errorHandler) {
    try {
        const data = await daoGet(endpoint, query);

        dispatch(addResourceRaw(resource, map(data)));
    } catch (e) {
        console.error(e)
        if (errorHandler !== undefined) {
            if (errorHandler !== false) {
                errorHandler(e)
            }
        } else {
            throw e;
        }
    }
}

export async function resourceGetAction({ dispatch, action, endpoint, query, map = o => o, errorHandler }) {
    try {
        const data = await daoGet(endpoint, query);

        dispatch(action(map(data)));
    } catch (e) {
        console.error(e)
        if (errorHandler !== undefined) {
            if (errorHandler !== false) {
                errorHandler(e)
            }
        } else {
            throw e;
        }
    }
}
