import { setFormValueAction } from "modules/redux/actions/form";
import { appDispatch } from 'modules/MRF';
import notification, { TYPE } from 'modules/utils/notification';

const initList = (obj, key) => {
    if (!(key in obj)) {
        obj[key] = [];
    }

    return obj[key];
};

const initObject = (obj, key) => {
    if (!(key in obj)) {
        obj[key] = {};
    }

    return obj[key];
}

const buildStructure = (output, path, errorMessage='') => {
    const splittedPath = path.split('.');
    const leftmostField = splittedPath[0].split('[')
    const fieldName = leftmostField[0];
    const idx = Number(leftmostField[1]?.slice(0, -1));

    if (idx >= 0) {
        const subOutput = initList(output, fieldName);
        splittedPath.shift();
        const subOutputIdx = initObject(subOutput, idx);
        buildStructure(subOutputIdx, splittedPath.join('.'), errorMessage);
    } else if (errorMessage instanceof Array) {
        output[fieldName] = errorMessage;
    } else {
        initList(output, fieldName).push(errorMessage);
    }
}

export const parseErrors = (response) => {
    const errors = {};

    for (const path in response?.modelState ?? []) {
        buildStructure(errors, path.substr(path.indexOf('.')+1), response.modelState[path]);
    }

    notification({ type: TYPE.ERROR, message: response?.message });
    return errors;
}

export const errorValidationHandler = (response, context, action = 'execute') => {
    response.errors = parseErrors(response);
    response._execute = action === 'execute'
    appDispatch.value(setFormValueAction(context?.resource + 'Errors', response));
}