import { addResourceRaw, deleteResourceRaw } from 'modules/redux/actions/resource';
import { appDispatch } from 'modules/MRF';

export const spinnerSingleton = {
    unfinishedRequests: 0,
    timeout: undefined,
    Overlay: 'div',
};

const showLoadingOverlay = () => {
    appDispatch.value?.(addResourceRaw('spinner', true));
};

const hideLoadingOverlay = () => {
    appDispatch.value?.(deleteResourceRaw('spinner'));
};

export default function spinnerWrapper(f) {
    return async function wrappedFunction() {
        if (++spinnerSingleton.unfinishedRequests === 1) {
            spinnerSingleton.timeout = setTimeout(() => {
                showLoadingOverlay();
            }, 500);
        }

        try {
            return await f.apply(null, arguments);
        } finally {
            setTimeout(() => {
                if (--spinnerSingleton.unfinishedRequests === 0) {
                    clearTimeout(spinnerSingleton.timeout);
                    hideLoadingOverlay();
                }
            }, 100);
        }
    }
}
