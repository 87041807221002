import * as actions from '../actions/print';

export const print = (state = false, action) => {
    switch (action.type) {
        case actions.SET_PRINT:
            return action.data;

        default:
            return state;
    }
}
