import tw, { styled, theme } from 'twin.macro';
import { BgColorHover } from 'styles/basic/BgColor';

export const StyledButton = styled(BgColorHover)`
    ${tw`flex text-base no-underline transition-colors duration-300 ease-out rounded focus:outline-none focus-visible:ring-1`}

    ${({ size }) => (size === 'small' ? tw`px-2` : tw`px-3 py-2`)}
    ${({ disabled }) => disabled && tw`pointer-events-none`}
    width: ${({ width }) => width && theme('width')[width]};
`;

export const StyledButtonIcon = styled.span`
    & > *:first-child {
        ${tw`block w-4 h-4 text-2xl`}
    }
`;

export const StyledButtonLabel = styled.span`
    ${tw`flex items-center justify-center w-full space-x-2 align-middle`}
`;
