import { Whatsapp } from '@styled-icons/fa-brands/Whatsapp';
import { PlusCircleOutline } from '@styled-icons/evaicons-outline/PlusCircleOutline';
import { MinusCircleOutline } from '@styled-icons/evaicons-outline/MinusCircleOutline';
import { DragIndicator } from '@styled-icons/material/DragIndicator';
import { ChevronRight } from '@styled-icons/boxicons-regular/ChevronRight';
import { UserCircle } from '@styled-icons/boxicons-solid/UserCircle';
import { HelpCircle } from '@styled-icons/boxicons-solid/HelpCircle';
import { HelpOutline } from '@styled-icons/material/HelpOutline';
import { Construction } from '@styled-icons/material/Construction';
import { Menu } from '@styled-icons/material/Menu';
import { Search } from '@styled-icons/material/Search';
import { Edit } from '@styled-icons/material/Edit';
import { ReadMore } from '@styled-icons/material/ReadMore';
import { ImageAdd } from '@styled-icons/boxicons-regular/ImageAdd';
import { Image } from '@styled-icons/boxicons-regular/Image';
import { Download } from '@styled-icons/evaicons-solid/Download';
import { Trash } from '@styled-icons/boxicons-regular/Trash';
import { DocumentAdd } from '@styled-icons/typicons/DocumentAdd';
import { ArrowForward } from '@styled-icons/evaicons-solid/ArrowForward';
import { ArrowBack } from '@styled-icons/evaicons-solid/ArrowBack';
import { CheckBox } from '@styled-icons/material/CheckBox';
import { IndeterminateCheckBox } from '@styled-icons/material/IndeterminateCheckBox';
import { CheckBoxOutlineBlank } from '@styled-icons/material/CheckBoxOutlineBlank';
import { Error } from '@styled-icons/boxicons-regular/Error';
import { FormNew } from '@styled-icons/fluentui-system-regular/FormNew';
import { Add } from '@styled-icons/material-rounded/Add';
import { MailSend } from '@styled-icons/boxicons-regular/MailSend';
import { InfoCircle } from '@styled-icons/fa-solid/InfoCircle';
import { Draft } from '@styled-icons/remix-line/Draft';
import { RateReview } from '@styled-icons/material-outlined/RateReview';
import { Approval } from '@styled-icons/material/Approval';
import { UserCheck } from '@styled-icons/fa-solid/UserCheck';
import { Publish } from '@styled-icons/entypo/Publish';
import { ErrorWarning } from '@styled-icons/remix-line/ErrorWarning';
import { Translate } from '@styled-icons/material/Translate';
import { GearWideConnected } from '@styled-icons/bootstrap/GearWideConnected';
import { FileEarmarkCheck } from '@styled-icons/bootstrap/FileEarmarkCheck';
import { Tools } from '@styled-icons/entypo/Tools'

// Search for icons at https://styled-icons.dev/

export { Twitter as TwitterIcon } from '@styled-icons/boxicons-logos/Twitter';
export { Facebook as FacebookIcon } from '@styled-icons/boxicons-logos/Facebook';
export { Youtube as YoutubeIcon } from '@styled-icons/boxicons-logos/Youtube';
export { Link as LinkIcon } from '@styled-icons/boxicons-regular/Link';
export { TabDesktopCopy as CopyIcon } from '@styled-icons/fluentui-system-filled/TabDesktopCopy';
export { NewReleases as ChangedFieldIcon } from '@styled-icons/material-outlined/NewReleases';
export { Report as ReportIcon } from '@styled-icons/boxicons-solid/Report';
export { FileCsv as CsvIcon } from '@styled-icons/fa-solid/FileCsv';
export { Printer as PrinterIcon } from '@styled-icons/boxicons-regular/Printer';
export { HourglassSplit as RemainingIcon } from '@styled-icons/bootstrap/HourglassSplit';
export { Check as CheckIcon } from '@styled-icons/boxicons-regular/Check';



export const WhatsappIcon = Whatsapp;
export const PlusIcon = PlusCircleOutline;
export const MinusIcon = MinusCircleOutline;
export const DragHandleIcon = DragIndicator;
export const ChevronIcon = ChevronRight;
export const UserIcon = UserCircle;
export const HelpIcon = HelpCircle;
export const HelpOutlineIcon = HelpOutline;
export const BuildIcon = Construction;
export const MenuIcon = Menu;
export const SearchIcon = Search;
export const EditIcon = Edit;
export const ViewIcon = ReadMore;
export const ImageAddIcon = ImageAdd;
export const ImageIcon = Image;
export const DownloadIcon = Download;
export const TrashIcon = Trash;
export const DocumentAddIcon = DocumentAdd;
export const ArrowRightIcon = ArrowForward;
export const ArrowBackIcon = ArrowBack;
export const CheckedBoxIcon = CheckBox;
export const InProgressBoxIcon = IndeterminateCheckBox;
export const UncheckedBoxIcon = CheckBoxOutlineBlank;
export const WarningIcon = Error;
export const AddItemIcon = Add;
export const SendIcon = MailSend;
export const TooltipIcon = InfoCircle;
export const NewFormIcon = FormNew;
export const DraftIcon = Draft;
export const ReviewIcon = RateReview;
export const ApprovalIcon = Approval;
export const ValidationIcon = UserCheck;
export const PublishedIcon = Publish;
export const ForbiddenIcon = ErrorWarning;
export const TranslationIcon = Translate;
export const SystemIcon = GearWideConnected;
export const FileCheckIcon = FileEarmarkCheck;
export const ToolsIcon = Tools;
