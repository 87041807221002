import React from 'react'
import T from 'modules/utils/T';
import tw, { styled } from "twin.macro";

const Variant = styled.span.attrs(
    ({ color }) => ({
        className: color && `text-${color}`,
    })
)`
    ${({ color }) => color === 'error' && tw`text-xs italic tracking-wide text-error`}
    ${({ align }) => [
        align === 'left' && tw`text-left`,
        align === 'center' && tw`text-center`,
        align === 'right' && tw`text-right`,
        align === 'justify' && tw`text-justify`,
    ]}
`;

export default function Text({
    children,
    variant = 'span',
    ...props
}) {
    return (
        <Variant as={variant} {...props}>
            <T>{children}</T>
        </Variant>
    );
}
