import tw, { theme } from "twin.macro";

export const justifyItemsVariants = {
    start: tw`justify-items-start`,
    end: tw`justify-items-end`,
    center: tw`justify-items-center`,
    stretch: tw`justify-items-stretch`,
}

export const justifySelfVariants = {
    auto: tw`justify-self-auto`,
    start: tw`justify-self-start`,
    end: tw`justify-self-end`,
    center: tw`justify-self-center`,
    stretch: tw`justify-self-stretch`,
}

export const justifyVariants = {
    start: tw`justify-start`,
    end: tw`justify-end`,
    center: tw`justify-center`,
    between: tw`justify-between`,
    stretch: tw`justify-items-stretch`,
}

export const alignVariants = {
    start: tw`items-start`,
    end: tw`items-end`,
    center: tw`items-center`,
    baseline: tw`items-baseline`,
    stretch: tw`items-stretch`,
}

export const alignSelfVariants = {
    auto: tw`self-auto`,
    start: tw`self-start`,
    end: tw`self-end`,
    center: tw`self-center`,
    stretch: tw`self-stretch`,
}

export const placeItemsVariants = {
    start: tw`place-items-start`,
    end: tw`place-items-end`,
    center: tw`place-items-center`,
    stretch: tw`place-items-stretch`,
}

export const placeSelfVariants = {
    auto: tw`place-self-auto`,
    start: tw`place-self-start`,
    end: tw`place-self-end`,
    center: tw`place-self-center`,
    stretch: tw`place-self-stretch`,
}

export const flowVariants = {
    col: tw`grid-flow-col`,
    colDense: tw`grid-flow-col-dense`,
    row: tw`grid-flow-row`,
    rowDense: tw`grid-flow-row-dense`,
}

export const spacing = {
    ...theme`spacing`
}