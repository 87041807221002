import Tippy from '@tippyjs/react';
import React from 'react';
import PropTypes from 'prop-types';
import T from 'modules/utils/T';

Tooltip.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    content: PropTypes.string,
    placement: PropTypes.string,
    disabled: PropTypes.bool,
};
  

export default function Tooltip({
    content,
    children,
    placement = 'top',
    disabled = false,
    disableTranslation = false,
    ...props
}) {
    if (!content) {
        disabled = true;
    }
    return (
        <Tippy
            content={disableTranslation ? content : <T>{content}</T>}
            placement={placement}
            disabled={disabled}
            className='whitespace-pre-line'
            {...props}
        >
            <span>
                {children}
            </span>
        </Tippy>
    );
}
