import * as actions from '../actions/systemParameters';

export const systemParameters = (state = {}, action) => {
    switch (action.type) {
        case actions.SET_SYSTEM_PARAMETERS_DATA:
            // Change all keys to be upper case.
            return Object.entries(action.data).reduce((obj, [k, v]) => ({ ...obj, [k.toUpperCase()]: v }), {});

        default:
            return state;
    }
}
