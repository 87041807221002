import React from 'react';
import tw, { styled } from 'twin.macro';
import BgColor from 'styles/basic/BgColor';

const SpinnerGrid = styled.div`
  width: 40px;
  height: 40px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);

  @-webkit-keyframes sk-cubeGridScaleDelay {
    0%,
    70%,
    100% {
      -webkit-transform: scale3D(1, 1, 1);
      transform: scale3D(1, 1, 1);
    }

    35% {
      -webkit-transform: scale3D(0, 0, 1);
      transform: scale3D(0, 0, 1);
    }
  }

  @keyframes sk-cubeGridScaleDelay {
    0%,
    70%,
    100% {
      -webkit-transform: scale3D(1, 1, 1);
      transform: scale3D(1, 1, 1);
    }

    35% {
      -webkit-transform: scale3D(0, 0, 1);
      transform: scale3D(0, 0, 1);
    }
  }
`;

const Cube = styled(BgColor)`
  width: 33%;
  height: 33%;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  -webkit-animation-delay: ${({ delay }) => delay};
  animation-delay: ${({ delay }) => delay};
`;

export const Spinner = ({ color = 'primary' }) => {
  return (
    <SpinnerGrid>
      <Cube color={color} delay="0.2s" />
      <Cube color={color} delay="0.3s" />
      <Cube color={color} delay="0.4s" />
      <Cube color={color} delay="0.1s" />
      <Cube color={color} delay="0.2s" />
      <Cube color={color} delay="0.3s" />
      <Cube color={color} delay="0s" />
      <Cube color={color} delay="0.1s" />
      <Cube color={color} delay="0.2s" />
    </SpinnerGrid>
  );
};

export const SpinnerWrapper = styled.div`
  ${tw`fixed top-0 left-0 z-50 flex items-center justify-center w-full h-screen animate-fadeIn bg-black bg-opacity-40`}
`;
