import * as actions from '../actions/auth';

export const auth = (state = null, action) => {
    switch (action.type) {
        case actions.SET_AUTH_DATA:
            return {
                ...state,
                ...action.data,
            };

        case actions.CLEAR_AUTH_DATA:
            return {};

        case actions.INCREMENT_KEY:
            return {
                ...state,
                updateKey: (state?.updateKey ?? 0) + 1,
            };

        default:
            return state;
    }
}
