import PropTypes from 'prop-types';
import tw, { styled, theme, css } from "twin.macro";
import {
    justifyItemsVariants,
    alignVariants,
    placeItemsVariants,
    flowVariants,
    spacing,
    justifySelfVariants,
    alignSelfVariants,
    placeSelfVariants,
} from 'modules/layout/variants';

const columnSpanVariants = {
    auto: tw`col-auto`,
    full: tw`col-span-full`,
}

const rowSpanVariants = {
    auto: tw`row-auto`,
    full: tw`row-span-full`,
}

export const Grid = styled.div.attrs(({
    component,
}) => ({
    as: component
}))`
    ${tw`grid`}
    
    grid-template-columns: ${({ cols = 12 }) => `repeat(${cols}, ${theme('gridAutoColumns')['fr']})`};
    ${({ direction }) => direction && flowVariants[direction]}

    ${({ justify }) => justify && justifyItemsVariants[justify]}
    ${({ align }) => align && alignVariants[align]}
    ${({ place }) => place && placeItemsVariants[place]}
    ${({ item }) => !item && tw`w-full`}
    
    width: ${({ width }) => spacing[width]};
    height: ${({ height }) => height && spacing[height]};

    gap: ${({ gap = 2 }) => gap && spacing[gap]};
    column-gap: ${({ gapX }) => gapX && spacing[gapX]};
    row-gap: ${({ gapY }) => gapY && spacing[gapY]};
`;

Grid.propTypes = {
    cols: PropTypes.number,
    gap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    gapX: PropTypes.number,
    gapY: PropTypes.number,
    direction: PropTypes.string,
    justify: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    align: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    place: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

const gridColumnSpan = (span) => span && css`
    ${typeof span === 'string' 
        ? columnSpanVariants[span]
        : `grid-column: span ${span} / span ${span}`};
    ${span === 'hidden' ? tw`hidden` : tw`block` }

`;

const gridRowSpan = (span) => span && css`
    ${typeof span === 'string' 
        ? rowSpanVariants[span]
        : `grid-row: span ${span} / span ${span}`};
    ${span === 'hidden' ? tw`hidden` : tw`block` }
`;

export const Col = styled.div.attrs(({
    component, grid
}) => ({
    as: grid ? Grid : component,
}))`
    && {
        ${({ grid }) => grid && tw`grid`};
    }
    
    ${({ rowSize = 'auto' }) => gridRowSpan(rowSize)};

    ${({ justify }) => justify && justifySelfVariants[justify]}
    ${({ align }) => align && alignSelfVariants[align]}
    ${({ place }) => place && placeSelfVariants[place]}

    ${({ xs }) => xs ? gridColumnSpan(xs) : tw`col-span-full`};

    @media (min-width: ${theme`screens.lg`}) {
        ${({ size = 'full' }) => gridColumnSpan(size)};
    }

    @media (min-width: ${theme`screens.sm`}) {
        ${({ sm }) => gridColumnSpan(sm)};
    }

    @media (min-width: ${theme`screens.md`}) {
        ${({ md }) => gridColumnSpan(md)};
    }

    @media (min-width: ${theme`screens.lg`}) {
        ${({ lg }) => gridColumnSpan(lg)};
    }

    @media (min-width: ${theme`screens.xl`}) {
        ${({ xl }) => gridColumnSpan(xl)};
    }
`;

Col.propTypes = {
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    rowSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    xl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    justify: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    align: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    place: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export const Row = styled.div.attrs(({
    component, grid
}) => ({
    as: grid ? Grid : component,
}))`
    && {
        ${({ grid }) => grid && tw`grid`};
    }
    
    ${({ colSize = 'auto' }) => gridColumnSpan(colSize)};

    ${({ justify }) => justify && justifySelfVariants[justify]}
    ${({ align }) => align && alignSelfVariants[align]}
    ${({ place }) => place && placeSelfVariants[place]}

    ${({ xs }) => xs ? gridRowSpan(xs) : tw`row-span-full`};

    @media (min-width: ${theme`screens.lg`}) {
        ${({ size = 'full' }) => gridRowSpan(size)};
    }

    @media (min-width: ${theme`screens.sm`}) {
        ${({ sm }) => gridRowSpan(sm)};
    }

    @media (min-width: ${theme`screens.md`}) {
        ${({ md }) => gridRowSpan(md)};
    }

    @media (min-width: ${theme`screens.lg`}) {
        ${({ lg }) => gridRowSpan(lg)};
    }

    @media (min-width: ${theme`screens.xl`}) {
        ${({ xl }) => gridRowSpan(xl)};
    }
`;

Row.propTypes = {
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    colSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    xl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    justify: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    align: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    place: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}