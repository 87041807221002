import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Prompt } from 'react-router';
import PropTypes from 'prop-types';
import path from 'path';
import { daoDelete, daoPost, daoPut } from 'modules/Dao';
import { resourceGetObject } from 'modules/redux/ResourceFetcher';
import { Spinner } from 'styles/components/Spinner';
import PageTitle from './Title';
import Button from './Button';
import { Grid, Col } from './Grid';
import { Unflatten } from '../redux/Mapper';
import { useDispatch, useSelector } from 'react-redux';
import Paper from 'modules/view/Paper';
import Separator from 'styles/basic/Separator';
import { deleteResourceRaw } from 'modules/redux/actions/resource';
import IF from 'modules/view/IF';
import mrfConfig from 'mrf.config';
import notification, { TYPE } from 'modules/utils/notification';
import { errorValidationHandler } from 'modules/utils/ValidationUtils';
import { setFormMetaValueByContextAction } from 'modules/redux/actions/form';
import useMRFTranslation from 'modules/utils/useMRFTranslation';
import mrf from "../../mrf.config";

export const FormContext = React.createContext();

export const mrfErrorValidationHandler = mrfConfig.overrideValidationFunction ?? errorValidationHandler

export const SubFormContext = ({ keys, children }) => {
    const context = useContext(FormContext);

    const subContext = useMemo(
        () => ({ ...context, keys: [ ...context.keys, ...keys ] }),
        [context, keys],
    )

    return <FormContext.Provider value={subContext} children={children} />;
}

export const formPromptMessage = 'Changes you made may not be saved.';

export const FormPrompt = ({ condition, message }) => {
    const t = useMRFTranslation();

    return <Prompt when={condition} message={message ?? t(formPromptMessage)} />
}

export const FormWrapper = ({ context, promptCondition = false, message, children }) => {
    useEffect(() => {
        if (promptCondition) {
            window.onbeforeunload = () => true;
        }

        return () => {
            window.onbeforeunload = undefined;
        }
    }, [promptCondition]);

    return <>
        <FormPrompt condition={promptCondition} message={message} />
        <FormContext.Provider value={context} children={children} />
    </>
}

FormCRUD.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,

    context: PropTypes.object,
    descriptor: PropTypes.object,
    title: PropTypes.any.isRequired, // Form title.
    id: PropTypes.any, // Determines if is Edit mode, id of the object being edited.
    map: PropTypes.func, // Mapping function to transform the object before submitting.
    validateForm: PropTypes.func, // Validate form before submitting.
    handleError: PropTypes.func, // Handle any errors returned by the endpoint.
    children: PropTypes.any,
    viewAfterPost: PropTypes.bool, // Whether or not to redirect to a View page after a successful submit
    viewAfterPostPath: PropTypes.string, // Redirect path after a successful submit. The ID will be automatically appended
    overrideAfterPath: PropTypes.string, // Override the path to go after the form is submitted.
    disableAutoValidate: PropTypes.bool, // Whether or not to autovalidate fields in back-end during edit
    handleCancel: PropTypes.func,
};

export default function FormCRUD({
    children,
    match,
    history,
    title,
    context,
    descriptor,
    id = match.params.id,
    map = o => o,
    validateForm = () => true,
    handleError,
    cancelLabel = 'Cancel',
    saveLabel = 'Save',
    viewAfterPost,
    viewAfterPostPath = '../view/',
    overrideAfterPath,
    disableAutoValidate = false,
    endpoint,
    hideDelete=false,
    hideButtons=false,
    actions,
    titlePrefix,
    handleCancel,
}) {
    const readOnly = context?.readOnly;

    const [bufferedTimeout, setBufferedTimeout] = useState();

    const form = useSelector(state => state?.forms?.[context?.resource]);

    const dispatch = useDispatch();

    const t = useMRFTranslation();

    const submitLock = useRef(false);

    endpoint = endpoint ?? `/api/${context?.resource}`;
    handleCancel = handleCancel ?? history.goBack;

    const submit = async action => {
        try {
            let objectId;

            const data = { ...form };
            delete data._meta;

            if (id) {
                await daoPut(`${endpoint}/${id}`, map(Unflatten(data, descriptor)), action);
                objectId = id;
            } else {
                const savedObject = await daoPost(endpoint, map(Unflatten(data, descriptor)), action);
                objectId = savedObject?.id;
            }

            return objectId;
        } catch (e) {
            mrfErrorValidationHandler(e, context, action);
            throw e;
        }
    }

    const handleSubmit = async e => {
        e.preventDefault();
        if (submitLock.current) {
            return;
        }

        if (!validateForm(form)) {
            return;
        }

        try {
            submitLock.current = true;
            clearTimeout(bufferedTimeout);
            const objectId = await submit('execute');

            dispatch(setFormMetaValueByContextAction(context, { dirty: false }));
            dispatch(deleteResourceRaw(context?.resource));

            // 'id' is undefined when posting a new object
            if (id) {
                resourceGetObject(dispatch, context?.resource, `${endpoint}/${id}`, undefined, descriptor);
                notification({ type: TYPE.SUCCESS, message: 'Successfully Edited' });
            } else {
                dispatch(deleteResourceRaw(context?.resource));
                notification({ type: TYPE.SUCCESS, message: 'Successfully Added' });
            }

            if (overrideAfterPath) {
                history.push(path.join(match.url, overrideAfterPath));
            } else if (!id && viewAfterPost) { // clients can choose to use a custom redirect path on posts
                history.push(path.join(match.url, viewAfterPostPath, `/${objectId}`));
            } else {
                history.push(path.join(match.url, id ? '../..' : '..'));
            }
        } catch (e2) {
            console.error(e2);
            handleError?.(e2);
        } finally {
            submitLock.current = false;
        }
    }

    // FIXME: Change object for something more meaningful
    const handleDelete = async () => {
        const confirmationMsg =
            form?.name ? t('Do you really want to delete "{{name}}"?', { name: form?.name })
            : title ? t('Do you really want to delete this {{title}}?', { title: title?.toLowerCase() })
            : t('Do you really want to delete this register?');

        if (id && window.confirm(confirmationMsg)) {    
            try {
                mrf.usePostForDelete ? await daoPost(`${endpoint}/${id}/delete`, '') 
                    : await daoDelete(`${endpoint}/${id}`);

                dispatch(setFormMetaValueByContextAction(context, { dirty: false }));
                dispatch(deleteResourceRaw(context?.resource));
                notification({ type: TYPE.SUCCESS, message: 'Successfully Deleted' });

                history.push(path.join(match.url, '../..'));
            } catch (e) {
                if (!e?.title) {
                    notification({ type: TYPE.ERROR, message: 'An unexpected error has occurred!' });
                }
                console.error(e);
            }
        }
    }

    //This is an auto-validator, we don't care about the error being caught.
    const validate = async () => {
        try {
            if (mrfConfig.validateForm) {
                await submit('validate');
            }
        } catch (e) {
            mrfErrorValidationHandler(e, context);
        }
    };

    useEffect(() => {
        if (disableAutoValidate) {
            return;
        }
        const timeout = setTimeout(validate, 1000);
        setBufferedTimeout(timeout);
        return () => clearTimeout(timeout);
    }, [form]); // eslint-disable-line

    if (!form) {
        return <Spinner />;
    }

    return <>
        <FormWrapper context={context} promptCondition={form?._meta?.dirty}>
            <div className='w-full'>
                <form onSubmit={handleSubmit}>
                    <Paper>
                        <PageTitle>{titlePrefix ?? (!readOnly && (id ? 'Edit' : 'New'))} {title}</PageTitle>

                        {children}

                        <IF condition={!readOnly && !hideButtons}>
                            <Separator />
                            <Grid>
                                <IF condition={!actions}>
                                    <Col size={3}>
                                        <IF condition={id && !hideDelete}>
                                            <Button color='delete' type='button' onClick={handleDelete}>Delete</Button>
                                        </IF>
                                    </Col>
                                    <Col size={3} />
                                </IF>
                                <IF condition={!!actions}>
                                    {actions}
                                </IF>
                                <Col size={3}>
                                    <Button color='secondary' type='button' onClick={handleCancel}>{cancelLabel}</Button>
                                </Col>
                                <Col size={3}>
                                    <Button color='primary' type='submit'>{saveLabel}</Button>
                                </Col>
                            </Grid>
                        </IF>
                    </Paper>
                </form>
            </div>
        </FormWrapper>
    </>;
}
