import React from 'react';
import T from 'modules/utils/T';
import buildInfo from 'buildInfo.json';
import mrfConfig from 'mrf.config'
import Paper from 'modules/view/Paper';
import { Grid, Col } from 'modules/view/Grid';
import PageTitle from 'modules/view/Title';
import Button from 'modules/view/Button';
import { getDaoErrors } from 'modules/Dao';
import { saveAs } from 'file-saver';
import useCurrentUser from "modules/utils/useCurrentUser";
import IF from './IF';
import ELSE from './ELSE';
import Paragraph from './Paragraph';
import { WarningIcon } from 'styles/components/Icons';
import { getNotificatonErrors } from 'modules/utils/notification';
// import CrashTest from './CrashTest';

export const downloadDebugFile = (user, fatalError) => {
    const systemTime = new Date().toISOString().replaceAll(':', '-');
    const url = window.location.href;
    // Remove user sensitive data
    const clonedUser = JSON.parse(JSON.stringify(user));
    clonedUser.roles = clonedUser.roles.map(o => o.role.code ).join(', ');
    delete user.phoneNumber;

    const data = {
        url,
        user: clonedUser,
        system: mrfConfig.applicationName,
        timestamp: systemTime,
        systemInfo: buildInfo,
        fatalError: fatalError?.message,
        fatalErroStack: JSON.stringify(fatalError?.stack),
        daoErrors: getDaoErrors(),
        notificationErrors: getNotificatonErrors(),
    };

    const fileContent = new Blob([ JSON.stringify(data) ], {type: 'text/plain'});

    saveAs(fileContent, `Debug_File_${systemTime}.json`);
}

export default function SystemInfo({ fatalError=false }) {
    const { buildTimestamp, branchName, commitHash, commitTimestamp } = buildInfo;
    const user = useCurrentUser();
    const systemBaseUrl = window.origin + (mrfConfig.homeURLSufix ? mrfConfig.homeURLSufix : "");

    return (
        <Paper className='max-w-screen-lg mx-auto mt-5'>
            <Grid>
                <IF condition={!!fatalError}>
                    <Col size={1}><WarningIcon /></Col>
                    <Col size={10}><PageTitle>Error</PageTitle></Col>
                    <Col size={1}><WarningIcon /></Col>
                    <Col size={2} />
                    <Col size={8}>
                        <Paragraph class="mb-5">
                            We are sorry to inform that a fatal error has occurred. Please follow the instructions:<br />
                               1. Try to reload the page (F5).<br />
                               2. If the error persists, download the Debug File and send it to support.<br />
                               3. Browse to home: <a class="underline" href={systemBaseUrl}>{systemBaseUrl}</a> <br />
                            <br />
                            Error: {fatalError.message}
                        </Paragraph>
                    </Col>
                    <Col size={2} />
                </IF>
                <ELSE condition={!!fatalError}>
                    {/* <CrashTest /> */}
                    <Col size={12}><PageTitle>System Information</PageTitle></Col>
                    <Col size={2}><T>Build Timestamp</T>:</Col>
                    <Col size={10}>{buildTimestamp}</Col>

                    <Col size={2}><T>Branch Name</T>:</Col>
                    <Col size={10}>{branchName}</Col>

                    <Col size={2}><T>Commit Hash</T>:</Col>
                    <Col size={10}>{commitHash}</Col>

                    <Col size={2}><T>Commit Timestamp</T>:</Col>
                    <Col size={10}>{commitTimestamp}</Col>
                </ELSE>
                <Col size={4} />
                <Col size={4}><Button color='primary' onClick={() => downloadDebugFile(user, fatalError)}>Download Debug File</Button></Col>
                <Col size={4} />
            </Grid>
        </Paper>
    );
}
