import Flex from 'modules/layout/Flex';
import tw, { theme, styled } from 'twin.macro';
import PropTypes from 'prop-types';

const Paper = styled(Flex).attrs(() => ({
    light: true,
}))`
    & {
        ${tw`flex-wrap bg-opacity-100 rounded-lg shadow`}
        ${({ color }) => color === 'secondary' ? tw`bg-paper-secondary` : tw`bg-paper`}
        ${({ padding }) => padding === 'none' && tw`p-0`}
        ${({ padding }) => !padding && tw`px-5 pt-6 pb-8 md:px-8`}
        padding: ${({ padding }) => padding && theme('padding')[padding]};
    }
`;

Paper.propTypes = {
    color: PropTypes.string,
    padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default Paper;