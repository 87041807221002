import * as actions from '../actions/resource';

export const resources = (state = {}, action) => {
    const innerState = state[action.resource];

    const getById = () => {
        const byId = { ...innerState?.byId };

        for (let i=0; i<action.data?.length; ++i) {
            const data = action.data[i];

            if (data.id !== undefined) {
                byId[data.id] = data;
            }
        }

        return byId;
    }

    switch (action.type) {
        case actions.ADD_RESOURCE:
            return {
                ...state,
                [action.resource]: {
                    ...innerState,
                    list: action.data?.map(data => data.id),
                    byId: getById(),
                },
            };

        case actions.ADD_QUERY_RESOURCE:
            return {
                ...state,
                [action.resource]: {
                    ...innerState,
                    byId: getById(),
                },
                [action.resource + 'Query']: {
                    ...state[action.resource + 'Query'],
                    list: action.data?.map(data => data.id),
                }
            };

        case actions.ADD_RESOURCE_BY_ID:
            return {
                ...state,
                [action.resource]: {
                    ...innerState,
                    byId: getById(),
                },
            };

        case actions.ADD_RESOURCE_RAW:
            return {
                ...state,
                [action.resource]: {
                    ...action.data,
                },
            };

        case actions.DELETE_RESOURCE_RAW:
            state = { ...state };
            delete state[action.resource];
            return state;

        default:
            return state;
    }
}
