import tw, { styled, theme } from 'twin.macro';

export const BgColor = styled.span`
    && {
        ${({ color, light, dark, disabled, textColor }) => [
            color && `background-color: ${theme('colors')?.[color]?.['DEFAULT'] ?? theme('colors')?.[color]?.['500'] ?? theme('colors.transparent')};`,
            color && `color: ${theme('textColor')?.[color]?.['contrast'] ?? 'black'};`,
            color && light && `background-color: ${theme('colors')?.[color]?.['light'] ?? theme('colors')?.[color]?.['200'] ?? theme('colors[card-secondary]')};`,
            color && dark && `background-color: ${theme('colors')?.[color]?.['dark'] ?? theme('colors')?.[color]?.['600'] ?? theme('colors.card')};`,
            color === 'card' && tw`bg-card text-color-normal`,
            color === 'paper' && tw`bg-paper text-color-normal`,
            textColor && `color: ${textColor};`,
            disabled && tw`bg-gray-300 text-color-disabled`,
        ]}
    }
`;

export const BgColorHover = styled(BgColor)`
    && {
        ${({ color, light, dark, disabled }) => [
            tw`hover:bg-card`,
            color === 'primary' && (light ? tw`hover:bg-primary` : (dark ? tw`hover:bg-primary-900` : tw`hover:bg-primary-dark`)),
            color === 'secondary' && (light ? tw`hover:bg-secondary` : (dark ? tw`hover:bg-secondary-900` : tw`hover:bg-secondary-dark`)),
            color === 'error' && (light ? tw`hover:bg-error` : (dark ? tw`hover:bg-error-900` : tw`hover:bg-error-dark`)),
            color === 'warning' && (light ? tw`hover:bg-warning` : (dark ? tw`hover:bg-warning-900` : tw`hover:bg-warning-dark`)),
            color === 'info' && (light ? tw`hover:bg-info` : (dark ? tw`hover:bg-info-900` : tw`hover:bg-info-dark`)),
            color === 'success' && (light ? tw`hover:bg-success` : (dark ? tw`hover:bg-success-900` : tw`hover:bg-success-dark`)),
            color === 'delete' && (light ? tw`hover:bg-delete` : (dark ? tw`hover:bg-delete-900` : tw`hover:bg-delete-dark`)),
            color === 'paper' && tw`hover:bg-card`,
            color === 'card' && tw`hover:bg-card-secondary`,
            disabled && tw`hover:bg-gray-300`,
        ]}
    }
`;

export default BgColor;