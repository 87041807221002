import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import T from 'modules/utils/T';
import path from 'path';
import { StyledButton, StyledButtonLabel, StyledButtonIcon  } from 'styles/components/Button.styles';
import { generateHtmlId } from 'modules/utils/StringGenerators';
import Tooltip from './Tooltip';

Button.propTypes = {
    match: PropTypes.object,
    relativePath: PropTypes.string,
    startIcon: PropTypes.any,
    component: PropTypes.elementType,
    children: PropTypes.any,
    htmlId: PropTypes.string,
    noTranslate: PropTypes.bool,
    textColor: PropTypes.string,
    /**
     * How large should the button be?
     */
    size: PropTypes.oneOf(['small', undefined]),
    width: PropTypes.string,
    color: PropTypes.string,
    tooltip: PropTypes.string,
};

Button.defaultProps = {
    component: 'button',
    noTranslate: false,
    width: 'full',
    color: 'transparent',
};
  

export default function Button({
    icon: Icon,
    children,
    match,
    relativePath,
    component,
    htmlId,
    noTranslate,
    tooltip,
    ...props
}) {
    const originalOnClick = props?.onClick;
    if (originalOnClick) {
        props.onClick = function(e) {
            e.preventDefault();
            originalOnClick.apply(null, arguments);
        }
    }

    // Set the element id inside the StyledButton or inside the Component
    htmlId = htmlId ?? generateHtmlId("button", children);

    const translated = noTranslate ? children : <T>{children}</T>

    const matchTo = match ? path.join(match.url, relativePath) : props.to;

    const iconHtml = useMemo(
        () => Icon && <StyledButtonIcon>
                <Icon />
            </StyledButtonIcon>
        , [Icon]
    )

    const buttonContent = <StyledButton {...props} as={component} to={matchTo} id={htmlId}>
        <StyledButtonLabel>
            {iconHtml}
            <span>{translated}</span>
        </StyledButtonLabel>
    </StyledButton>;

    return tooltip
        ? <Tooltip content={tooltip} children={buttonContent} />
        : buttonContent;
}
