import { MODEL_TYPE_LIST, MODEL_TYPE_NESTED_LIST, MODEL_TYPE_OBJECT, MODEL_TYPE_DATE } from 'models';
import { SortSequence } from '../utils/Sorters';
import { addResourceByIdAction } from './actions/resource';
import store from './store';

export const Flatten = (dispatch, obj, descriptor = []) => {
    obj = { ...obj };

    for (const fieldName in descriptor) {
        if (!descriptor.hasOwnProperty(fieldName)) {
            continue;
        }
        const field = descriptor[fieldName];
        const fieldValue = obj[fieldName];
        if (!fieldValue) {
            continue;
        }

        const sorter = field.sorter ?? SortSequence;

        switch (field.type) {
            case MODEL_TYPE_OBJECT:
                dispatch(addResourceByIdAction(field.resource, [ Flatten(dispatch, fieldValue, field.descriptor) ]));
                if (field.readOnly) {
                    delete obj[fieldName];
                } else {
                    obj[fieldName] = fieldValue.id;
                }
                break;

            case MODEL_TYPE_LIST:
                obj[fieldName] = sorter(fieldValue).map(o => {
                    dispatch(addResourceByIdAction(field.resource, [ Flatten(dispatch, o, field.descriptor) ]));
                    return o.id;
                });
                break;

            case MODEL_TYPE_NESTED_LIST:
                obj[fieldName] = sorter(fieldValue).map(o => Flatten(dispatch, o, field.descriptor));
                break;

            case MODEL_TYPE_DATE:
                obj[fieldName] = new Date(fieldValue);
                break;
            
            default:
                console.warn("Unknown Field type: " + field.type)
                break;
        }
    }

    return obj;
}

export const UnflattenFullObject = (obj, descriptor = []) => Unflatten(obj, descriptor, true);

export const Unflatten = (obj, descriptor = [], fullObject=false) => {
    const state = store.getState();
    obj = { ...obj };
    
    for (const fieldName in descriptor) {
        
        if (!descriptor.hasOwnProperty(fieldName)) {
            continue;
        }
        const field = descriptor[fieldName] || (fullObject ? descriptor[fieldName + "Id"] : undefined);

        const fieldValue = obj[fieldName] || (fullObject ? obj[fieldName + "Id"] : undefined);
        if (!fieldValue) {
            continue;
        }

        switch (field.type) {
            case MODEL_TYPE_OBJECT:
                obj[fieldName] = Unflatten(state.resources[field.resource]?.byId?.[fieldValue], field.descriptor);
                break;

            case MODEL_TYPE_LIST:
                obj[fieldName] = fieldValue.map(id => Unflatten(state.resources[field.resource]?.byId?.[id], field.descriptor));
                break;

            case MODEL_TYPE_NESTED_LIST:
                obj[fieldName] = fieldValue.map(o => Unflatten(o, field.descriptor));
                break;
            
            default:
                break;
        }
    }

    return obj;
}
