import { combineReducers } from 'redux';
import { resources } from './resource';
import { forms } from './form';
import { auth } from './auth';
import { print } from './print';
import { systemParameters } from './systemParameters';
import { scrollToTop } from './scrollToTop';
import { counters } from './counters';

export const rootReducer = combineReducers({ resources, forms, auth, systemParameters, scrollToTop, print, counters });
