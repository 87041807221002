import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from 'App';
import store from 'modules/redux/store';
import { Provider } from 'react-redux';
import { Spinner } from 'styles/components/Spinner';
import './i18n';

import 'styles/tailwind.css';

export const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <Suspense fallback={<Spinner />}>
            <Provider store={store}>
                <App />
            </Provider>
        </Suspense>
    </BrowserRouter>,
    rootElement
);
