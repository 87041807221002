import React from 'react';
import PropTypes from 'prop-types';


const IFNonMemoed = ({ condition, children = null, alt = null }) => {
    return condition ? children : alt;
};

const IF = React.memo(IFNonMemoed);

IF.propTypes = {
    condition: PropTypes.any.isRequired,
}

export default IF;
