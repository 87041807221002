import { useDispatch, useSelector } from 'react-redux';
import {useContext, useEffect, useState} from 'react';
import { resourceGetList, resourceGetObject, resourceGetRaw } from './ResourceFetcher';
import { deleteResourceRaw } from './actions/resource';
import { setFormValueAction, deleteFormAction, setFormValueByContextAction } from './actions/form';
import { UnflattenFullObject } from './Mapper';
import capitalize from 'modules/utils/capitalize';
import {FormContext} from "../view/FormCRUD";
import { useCallback } from 'react';

export const TYPE_GET_LIST = 'LIST';
export const TYPE_GET_OBJECT = 'OBJECT';
export const TYPE_GET_RAW = 'RAW';

export const useFetcherSelectorById = ({
    id,
    resource,
    ...props
}) => useFetcherSelector({
    selector  : state => state?.resources?.[resource]?.byId?.[id],
    type      : TYPE_GET_OBJECT,
    endpoint  : `/api/${resource}/${id}`,
    resource,
    ...props,
});

export const useFetcherSelector = ({
    selector,
    type,
    resource,
    endpoint,
    condition = i => !i,
    query = {},
    descriptor,
    sorter = i => i,
    map = i => i,
    cleanup = false,
    unflatten = false,
    dependencyList = [],
    errorHandler
}) => {
    const obj = useSelector(selector);

    const dispatch = useDispatch();

    useEffect(() => {
        if (condition(obj)) {
            switch(type) {
                case TYPE_GET_LIST:
                    resourceGetList({ dispatch, resource, endpoint, query, sorter, descriptor, errorHandler });
                    break;

                case TYPE_GET_OBJECT:
                    resourceGetObject(dispatch, resource, endpoint, query, descriptor, errorHandler);
                    break;

                case TYPE_GET_RAW:
                    resourceGetRaw(dispatch, resource, endpoint, query, map, errorHandler);
                    break;

                default:
                    console.error(`useFetcherSelector type ${type} unknown`);
            }
        }

        if (cleanup) {
            switch(type) {
                case TYPE_GET_LIST:
                    break;

                case TYPE_GET_OBJECT:
                    break;

                case TYPE_GET_RAW:
                    return () => dispatch(deleteResourceRaw(resource));

                default:
                    console.error(`useFetcherSelector type ${type} unknown`);
            }
        }
    }, dependencyList); // eslint-disable-line

    return unflatten ? UnflattenFullObject(obj, descriptor, true) : obj;
}

export const useFormSelector = ({
    id,
    resource,
    formName = resource,
    endpoint = `/api/${resource}/${id}`,
    blankForm,
    descriptor,
    blankCondition = () => !id,
    initForm = o => o,
    readOnly = false,
}) => {
    const dispatch = useDispatch();

    const resourceData = useFetcherSelector({
        selector: (state) => state?.resources?.[resource]?.byId?.[id],
        type: TYPE_GET_OBJECT,
        resource,
        endpoint,
        descriptor,
        condition: () => typeof resource === "string" && id,
    });

    // Init form
    useEffect(() => {
        if (blankCondition()) {
            dispatch(setFormValueAction(formName, blankForm?.()));
        }
    }, [id]); // eslint-disable-line

    // Load data when resource is loaded/reloaded
    useEffect(() => {
        if (resourceData) {
            dispatch(setFormValueAction(formName, initForm(resourceData)));

            return () => dispatch(deleteFormAction(formName));
        }
    }, [resourceData]); // eslint-disable-line

    // Cleanup
    useEffect(() => {
        return () => {
            dispatch(deleteFormAction(formName));
            dispatch(deleteFormAction(formName + 'Errors'));
        }
    }, []); // eslint-disable-line

    return useState({
        resource,
        keys: [],
        readOnly
    })[0];
}

export const useDialogFormSelector = ({ resource, initForm }) => {
    const context = useFormSelector({ resource });
    const dispatch = useDispatch();

    const [ isOpen, setIsOpen ] = useState(false);

    context.isOpen = isOpen;

    context.open = () => {
        setFormValueByContext(dispatch, context, initForm?.());
        setIsOpen(true);
    };
    context.close = () => setIsOpen(false);

    return context;
}

export const useFormDataSelector = (context, field=false) => {
    return useSelector(state => {
        let ptr = state?.forms?.[context?.resource];

        for (let k of context.keys ?? []) {
            ptr = ptr?.[k];
        }

        return field ? ptr?.[field] : ptr;
    });
}

export const useFormErrorSelector = (context, field=false, force=false) => {
    return useSelector(state => {
        let ptr = state?.forms?.[context?.resource + 'Errors'];
        if (!force && !ptr?._execute) {
            return undefined;
        }

        ptr = ptr?.errors;
        for (let k of context.keys ?? []) {
            const finalKey = typeof k === 'string' ? capitalize(k) : k;
            ptr = ptr?.[finalKey];
        }

        return field ? ptr?.[field] : ptr;
    });
}

export const setFormValueByContext = (dispatch, context, data, meta) => {
    dispatch(setFormValueByContextAction(context, data, meta));
}

export const useSetFormValueByContext = (context) => {
    const dispatch = useDispatch();

    return (data, meta) => setFormValueByContext(dispatch, context, data, meta);
}

export const useFormFullSelector = (context, field=false) => {
    return {
        resource: context.resource,
        data: useFormDataSelector(context, field),
        set: useCallback(useSetFormValueByContext(context), [context, field]),
    };
}

export const useFormContext = (context) =>{
    const formContext = useContext(FormContext);
    return context ?? formContext;
}
