import React from 'react';
import SystemInfo from './SystemInfo';

// Only class components can implement ErrorBoundary:
// See https://pt-br.reactjs.org/docs/error-boundaries.html
class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error) {
      this.setState({
        error: error,
      })

    }

    render() {
      return this.state.error
        ? <SystemInfo fatalError={this.state.error} />
        : this.props.children;
    }
}

export default ErrorBoundary;