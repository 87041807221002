import Flex from 'modules/layout/Flex';
import React from 'react';
import T from 'modules/utils/T';
import IF from './IF';

export default function PageTitle({ children, actions, disableTranslation }) {
    return (
        <div className='flex justify-center w-full mb-3 text-xl font-semibold'>
            <Flex align='center' justify='center'>
                <h3 className='uppercase'>{disableTranslation ? children : <T>{children}</T>}</h3>
                <IF condition={!!actions}>{actions}</IF>
            </Flex>
        </div>
    );
}
