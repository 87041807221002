import mrf from 'mrf.config';
import styled from "styled-components";
import Text from 'styles/basic/Text';
import tw from "twin.macro";
import T from "../utils/T";

export const StyledBanner = styled.div`
    ${tw`sticky -top-10 flex justify-center w-full p-2 bg-red-300`}
`;

export default function DevBanner({children}) {
    if(!mrf.showDevBanner || !window.location.hostname.endsWith("maestrotechsoft.com")){
        return children;
    }
    
    return(<>
        <div className="relative">
            <StyledBanner>
                <Text><T>This is the test environment!</T></Text>
            </StyledBanner>
            {children}
        </div>
    </>);
}