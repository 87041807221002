export const ADD_RESOURCE = 'ADD_RESOURCE';
export const ADD_QUERY_RESOURCE = 'ADD_QUERY_RESOURCE';
export const ADD_RESOURCE_BY_ID = 'ADD_RESOURCE_BY_ID';
export const ADD_RESOURCE_RAW = 'ADD_RESOURCE_RAW';
export const DELETE_RESOURCE_RAW = 'DELETE_RESOURCE_RAW';

export const addResourceAction = (resource, data) => ({ type: ADD_RESOURCE, resource, data });
export const addQueryResourceAction = (resource, data) => ({ type: ADD_QUERY_RESOURCE, resource, data });
export const addResourceByIdAction = (resource, data) => ({ type: ADD_RESOURCE_BY_ID, resource, data });
export const addResourceRaw = (resource, data) => ({ type: ADD_RESOURCE_RAW, resource, data });
export const deleteResourceRaw = (resource) => ({ type: DELETE_RESOURCE_RAW, resource });
