import * as actions from '../actions/counter';

export const counter = (state = 0, action) => {
    switch (action.type) {
        case actions.SET_COUNTER_VALUE:
            return action.value;

        case actions.INCREMENT_COUNTER:
            return state + 1;

        default:
            return state;
    }
}

export const counters = (state = {}, action) => {
    switch (action.type) {
        case actions.SET_COUNTER_VALUE:
        case actions.INCREMENT_COUNTER:
            return {
                ...state,
                [action.counter]: counter(state[action.counter], action),
            }

        default:
            return state;
    }
}
