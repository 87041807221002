import React from 'react';
import { toast } from "react-toastify";
import T from 'modules/utils/T';

export const notificationErrors = [];

export const TYPE = toast.TYPE;

export const getNotificatonErrors = () => {
    return notificationErrors;
}

const addNotificationError = (message, error) => {
    const systemTime = new Date().toISOString().replaceAll(':', '-');
    const url = window.location.href;

    notificationErrors.push({
        systemTime,
        url,
        message,
        errorMessage: error?.message,
        errorStack: JSON.stringify(error?.stack)
    });
    notificationErrors.splice(0, notificationErrors.length - 1000);
}

export default function notification({ type, message, ...props }) {
    if (type === TYPE.ERROR) {
        console.warn("Please use notifyError(message, error) to report errors.");
        addNotificationError(message);
    }

    return toast(() => <T>{message}</T>, { type, ...props });
}

export const notifyError = (message, error, ...props) => {
    addNotificationError(message, error);
    const type = TYPE.ERROR;
    return toast(() => <T>{message}</T>, { type, ...props });
}
