import querystring from 'querystring';
import { apiPrefix } from 'App';
import notification, { TYPE } from './utils/notification';
import { attemptTokenRenewal, logout } from './utils/LoginUtils';
import spinnerWrapper from './utils/spinnerWrapper';
import urljoin from 'url-join';
import mrf from 'mrf.config';

export const defaultMaxResponseLength = 100;
export const defaultAlertResponseLength = 28;

export const daoErrors = [];

export const getDaoErrors = () => {
    return daoErrors;
}

const putDaoError = (endpoint, request, response) => {
    // Remove sensitive data if needed
    const clonedRequest = JSON.parse(JSON.stringify(request));
    delete clonedRequest.headers?.Authorization;

    const systemTime = new Date().toISOString().replaceAll(':', '-');
    const url = window.location.href;

    daoErrors.push({ systemTime, url, endpoint, request: clonedRequest, response });
    daoErrors.splice(0, daoErrors.length - 1000);

}

export const logFetch = async (input, init) => {
    const response = await fetch(input, init);
    try {
        if (response?.status >= 400) {
            const content = response.json
                ? await response.clone().json()
                : response.clone();

            putDaoError(input, init, { status: response?.status, body: content });
        }
    } catch (e) {
        console.warn("Unable to process logFetch response: " + e.message);
        putDaoError(input, init, { status: response?.status, jsException: e.message });
    }
    return response;
}

const fetchJson = async (input, init) => {
    if (localStorage.token && new Date(localStorage.tokenExp) < new Date()) {
        logout();
        console.log('Token expired!');
        return;
    }

    const url = apiPrefix && !input.startsWith('http:') ? urljoin(apiPrefix, input) : input;

    const response = await logFetch(url, {
        ...init,
        headers: {
            'Content-Type': 'application/json',
            'Accept-Language': localStorage?.language ?? 'en',
            ...init?.headers,
            ...localStorage.token ? { Authorization: localStorage.token } : {},
        },
    });

    const responseLength = (await response.clone().text()).length

    const maxResponseLength = mrf.getMaxResponseLength?.(input) ?? defaultMaxResponseLength << 20; // Maximum response length allowed (100mb)
    const alertResponseLength = mrf.getAlertResponseLength?.(input) ?? defaultAlertResponseLength << 20; //Warning response length (28mb)

    if (responseLength > maxResponseLength) {
        notification({ type: TYPE.ERROR, message: "There seems to be something wrong in this page, please contact the support." });
        throw new Error("There seems to be something wrong in this page, please contact the support.");
    } else if (responseLength > alertResponseLength) {
        notification({ type: TYPE.ERROR, message: "There seems to be something wrong in this page, please contact the support." });
    }

    attemptTokenRenewal();

    if (response.status === 401) {
        logout();
        console.log('Token is invalid!');
        return;
    }

    if (response.status === 403) {
        const responseJson = await response.json();
        if (responseJson?.title) {
            notification({ type: TYPE.ERROR, message: responseJson?.title });
        }
        throw responseJson;
    }

    // NoContent
    if (response.status === 204) {
        return null;
    }

    if (response.status && (response.status < 200 || response.status >= 300)) {
        let responseJson;

        try {
            responseJson = await response.json();
        } catch (e) {
            console.warn("Failed to parse json response at fetchJson: " + e.message);
            throw response;
        }

        if (responseJson?.title && init?.headers?.['x-action-intent'] !== 'validate') {
            notification({ type: TYPE.ERROR, message: responseJson?.title });
        }

        throw responseJson;
    }

    try {
        return await response.json();
    } catch (e) {
        console.warn("Failed to parse json response at fetchJson: " + e.message);
        throw e;
    }
}

const spinnerFetchJson = spinnerWrapper(fetchJson);

export async function daoGet(endpoint, query) {
    const queryString = querystring.stringify(query);
    return spinnerFetchJson(queryString ? `${endpoint}?${queryString}` : endpoint, {});
}

export async function daoPut(endpoint, data, action = 'execute') {
    const func = action === 'execute' ? spinnerFetchJson : fetchJson;

    return func(endpoint, {
        headers: { 'x-action-intent': action },
        method: 'PUT',
        body: JSON.stringify(data),
    });
}

export async function daoPost(endpoint, data, action = 'execute', useSpinner = true) {
    const func = useSpinner && action === 'execute' ? spinnerFetchJson : fetchJson;

    return func(endpoint, {
        headers: { 'x-action-intent': action },
        method: 'POST',
        body: JSON.stringify(data),
        action,
    });
}

export async function daoDelete(endpoint, data) {
    return spinnerFetchJson(endpoint, {
        method: 'DELETE',
        body: JSON.stringify(data),
    });
}

export function daoGetFile(endpoint, data) {
    const prefix = window.location.hostname === 'localhost' ? window.location.origin + '/en/sms/tkp' : '/Content';
    return daoGet(prefix + endpoint, data);
}

export async function daoGetFileCached(fileName, endpoint, validThru, data) {
    const cache = JSON.parse(localStorage[fileName] ?? '{}');

    if (endpoint !== cache.endpoint || (validThru && !cache.validThru) || new Date() > new Date(cache.validThru)) {
        const ret = await daoGetFile(endpoint, data);

        localStorage[fileName] = JSON.stringify({
            data: JSON.stringify(ret),
            endpoint: endpoint,
            validThru: validThru,
        });

        return ret;
    }

    return JSON.parse(cache.data);
}
