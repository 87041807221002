export const SET_FORM_VALUES = 'SET_FORM_VALUES';
export const SET_FORM_META_VALUES = 'SET_FORM_META_VALUES';
export const DELETE_FORM = 'DELETE_FORM';

export const setFormValueAction = (form, data, meta) => ({ type: SET_FORM_VALUES, form, data, meta });
export const setFormNestedValueAction = (form, keys, data, meta) => ({ type: SET_FORM_VALUES, form, keys, data, meta });
export const setFormValueByContextAction = ({ resource: form, keys }, data, meta) => ({ type: SET_FORM_VALUES, form, keys, data, meta });

export const setFormMetaValueAction = (form, meta) => ({ type: SET_FORM_META_VALUES, form, meta });
export const setFormMetaValueByContextAction = ({ resource: form }, meta) => ({ type: SET_FORM_META_VALUES, form, meta });

export const deleteFormAction = (form) => ({ type: DELETE_FORM, form });
