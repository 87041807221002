import * as actions from '../actions/scrollToTop';

export const scrollToTop = (state = true, action) => {
    switch (action.type) {
        case actions.DISABLE_SCROLL_TO_TOP:
            return false;

        case actions.ENABLE_SCROLL_TO_TOP:
            return true;

        default:
            return state;
    }
}
