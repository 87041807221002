import moment from 'moment';

/**
 * Generate HTML Id based on given prefix, value and separator.
 */
export function generateHtmlId(prefix, id, separator='_') {
    if (!id) {
        console.warn('Undefined id found for element.');
        return 'UNDEFINED_ID';
    }

    //Sometimes, we receive an object here. So, we have to make sure it is a string first
    let convertedId = id.toString();
    // Replace invalid chars: .\/
    convertedId = convertedId.replace(/[ .\\/]/g, separator);

    convertedId = convertedId.replace(/>/g, 'gt');
    convertedId = convertedId.replace(/</g, 'lt');

    return prefix + separator + convertedId;
}

/**
 * Serialize an object to an URL query parameters.
 */
export function toURLQuery (obj) {
    const str = [];

    const pushParameter = (parameter, value = obj[parameter]) => {
        if (value instanceof Date) {
            str.push(encodeURIComponent(parameter) + '=' + encodeURIComponent(moment(value).format('YYYY-MM-DDThh:mm:ss')));
        } else if (value !== null) {
            str.push(encodeURIComponent(parameter) + '=' + encodeURIComponent(value));
        }
    }

    for (var p in obj) {
        if (!obj.hasOwnProperty(p)) {
            continue;
        }

        if (obj[p] instanceof Array) {
            for (var arrayItem of obj[p]) {
                pushParameter(p, arrayItem[p]);
            }
        } else {
            pushParameter(p);
        }
    }

    return str.join('&');
}