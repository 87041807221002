export const NoSorting = list => list;

export const SortSequence = list => [...list].sort((a, b) => a.sequence - b.sequence);

export const ElementMover = (list, source, destination) => {
    list = [ ...list ]

    const deltaIdx = destination > source ? 1 : -1;
    const movingObj = list[source];
    let auxSequence = movingObj.sequence;
    for (let i = source; i !== destination; i += deltaIdx) {
        [ auxSequence, list[i + deltaIdx].sequence ] = [ list[i + deltaIdx].sequence, auxSequence ];
        list[i] = list[i + deltaIdx];
    }
    list[destination] = movingObj;
    list[destination].sequence = auxSequence;

    return list;
}
