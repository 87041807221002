import React from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import {
    SpinnerWrapper,
    Spinner as StyledSpinner,
} from 'styles/components/Spinner';

export default function Spinner() {
    if (!useSelector((state) => state?.resources?.spinner)) {
        return false;
    }

    return ReactDOM.createPortal(
        <SpinnerWrapper aria-hidden>
            <StyledSpinner />
        </SpinnerWrapper>,
        document.body
    )
}
